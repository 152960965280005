import React from "react"
import Layout from "../../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import PageSplash from "../../components/PageSplash/PageSplash"
import { ValueProp, ValuePropHeader } from "../../components/ValueProp/ValueProp"
import IconRow from "../../components/IconRow/IconRow"
import IconRowItem from "../../components/IconRow/IconRowItem"

import { MediaAndText } from "../../components/ImageAndText/ImageAndText"
import PreHeading from "../../components/PreHeading"

import LoginIcon from "../../images/icons/log-in.inline.svg"
import PieIcon from "../../images/icons/pie.inline.svg"
import ChevRightIcon from "../../images/icons/chev-right.inline.svg"
import MaximizeIcon from "../../images/icons/maximize.inline.svg"
import CopyIcon from "../../images/icons/copy.inline.svg"
import ScissorIcon from "../../images/icons/scissor.inline.svg"
import LocationIcon from "../../images/icons/location.inline.svg"
import GlobeIcon from "../../images/icons/globe.inline.svg"
import Icon247 from "../../images/icons/24-7.inline.svg"
import BarChartIcon from "../../images/icons/bar-chart.inline.svg"
import LayersIcon from "../../images/icons/layers.inline.svg"
import TabletIcon from "../../images/icons/tablet.inline.svg"
import TrendingUpIcon from "../../images/icons/trending-up.inline.svg"
import UmbrellaIcon from "../../images/icons/umbrella.inline.svg"
import FilterIcon from "../../images/icons/filter.inline.svg"
import MessageIcon from "../../images/icons/message.inline.svg"
import SmileIcon from "../../images/icons/smile.inline.svg"
import UsersIcon from "../../images/icons/users.inline.svg"
import FeatherIcon from "../../images/icons/feather.inline.svg"
import SettingsIcon from "../../images/icons/settings.inline.svg"

import ScreenshotGameImage from "../../images/pages/products/culture/game-screenshot-en.svg"
import ScreenshotStatisticImage from "../../images/pages/products/culture/screenshot-statistic-en.svg"

import HighlightSection from "../../components/HighlightSection"
import { StepperItem } from "../../components/Stepper/Stepper"
import CustomerSlider from "../../components/CustomerSlider"
import Seo from "../../components/Seo"
import { SupportEn } from "../../components/Support"
import { StartNowEn } from "../../components/StartNow"
import { PageType } from "../../index"
import DashboardScreenshotEn from "../../images/pages/products/culture/dashboard-screenshot-en"
import ProductNav from "../../components/ProductNav/ProductNav"

const CultureEn: PageType = ({ location }) => {
    const {
        splash,
    } = useStaticQuery(graphql`
        query CultureEn {
            splash: file(relativePath: { eq: "pages/products/culture/splash.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, maxHeight: 680) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return <Layout location={location}>
        <Seo
            title="Corporate Culture"
        />
        <ProductNav />
        <PageSplash
            preHeading="Making onboarding work"
            heading="Multiple Locations, One Vision"
            cta="Explore"

            image={splash}
        />
        <span aria-hidden id="content" />
        <ValueProp>
            <ValuePropHeader
                preHeading="The “First Who, Then What” Concept"
                heading="Coming together is a beginning. Keeping together is progress. Working together is success. – Henry Ford"
            >
                <p>
                    The driving factor is not the word uttered. What matters most are the values and the way you live them by setting an example. A thought, an idea and knowledge all proceed to set forth an action. It is this pivotal point of human actions that succinctly defines our work. Create and activate an ROI of an effective onboarding program that engage, excite, educate and connect.
                </p>
            </ValuePropHeader>

            <IconRow perRow={5}>
                <IconRowItem icon={<LoginIcon />}>
                    Efficacious Onboarding
                </IconRowItem>
                <IconRowItem icon={<ChevRightIcon />}>
                    Efficient Knowledge Transfer
                </IconRowItem>
                <IconRowItem icon={<PieIcon />}>
                    Departmental Assessments
                </IconRowItem>
                <IconRowItem icon={<MaximizeIcon />}>
                    Scalable Models
                </IconRowItem>
                <IconRowItem icon={<CopyIcon />}>
                    Training Templates
                </IconRowItem>
            </IconRow>
        </ValueProp>
        <section className="section-x-margin">
            <MediaAndText
                cutOff
                noImageLimit
                contentSide={{
                    desktop: "left",
                    mobile: "bottom",
                }}
                image={<DashboardScreenshotEn className="w-full pointer-events-none" />}
            >
                <PreHeading>Employee Branding</PreHeading>
                <h2>Core Value Ambassadors</h2>
                <p>
                    Set up common values and common goals regardless of differences in personalities and strengths. QUADIO equips you with a digital, scalable tool that successfully converts employees into value ambassadors.
                </p>
                <ul className="list-blue-bullet mt-8">
                    <li>Crafting an effective employee branding</li>
                    <li>Communicating the corporate culture</li>
                    <li>Conveying the vision and strategy of the organization</li>
                </ul>
            </MediaAndText>
        </section>
        <section className="section-x-margin">
            <MediaAndText
                contentSide={{
                    desktop: "right",
                    mobile: "bottom",
                }}
                cutOff
                noImageLimit
                image={<img alt="" src={ScreenshotGameImage} className="w-full pointer-events-none" />}
            >
                <PreHeading>Onboarding</PreHeading>
                <h2>Get Onboard!</h2>
                <p>
                    A tedious onboarding process bears enormous expenses. Create effective, effortless and personalized onboarding experiences that boost the transmission of corporate values and culture.
                </p>
                <ul className="list-blue-bullet mt-8">
                    <li>Competent and holistic learning experience</li>
                    <li>Ensuring a sustainable compliance framework</li>
                    <li>Personalized model</li>
                    <li>Seamless experience through powerful templates</li>
                </ul>
            </MediaAndText>
        </section>
        <HighlightSection
            stepperItems={<>
                <StepperItem
                    heading="Custom Made Solutions"
                    icon={<ScissorIcon />}
                >
                    Your core values, vision & mission and corporate culture – each individually adapted for employees to identify with the organization.
                </StepperItem>
                <StepperItem
                    heading="Regardless of time and location"
                    icon={<LocationIcon />}
                />
                <StepperItem
                    heading="Remote coaching"
                    icon={<Icon247 />}
                />
                <StepperItem
                    heading="Assessment and evaluation"
                    icon={<BarChartIcon />}
                />
                <StepperItem
                    heading="Stable and standardized quality"
                    icon={<GlobeIcon />}
                />
            </>}
        >
            <div className="mb-8">
                <PreHeading>Distant Leading</PreHeading>
                <h2>Leading at a distance</h2>
                <p>
                    Steering geographically dispersed teams and its leadership from distance entail its own challenges. Yet, regardless of the distance, with adequate support, the right technology and training solutions distant leading will deliver high performance and innovation. Whether it is to foster employee relationships, to pursue a collaborative focus towards objectives or to shape the corporate culture QUADIO is the key for the future of leading from distance ensuring the vision and strategy of the organization are firmly implanted in the minds of the employees.
                </p>
            </div>
        </HighlightSection>
        <section className="section-x-margin">
            <MediaAndText
                contentSide={{
                    desktop: "right",
                    mobile: "bottom",
                }}
                cutOff
                noImageLimit
                image={<img alt="" src={ScreenshotStatisticImage} className="w-full pointer-events-none" />}
            >
                <PreHeading>
                    Assessments Based on the Fundamental Principle of Actual Time
                </PreHeading>
                <h2>A Holistic Framework for Effective Execution</h2>
                <p>
                    Based on advanced, systematic analysis, Quadio provides actual time insights into a holistic understanding of management in order to execute effectively and innovatively.
                </p>
            </MediaAndText>
        </section>
        <SupportEn>
            <li>Project assistance and strategy focused consulting</li>
            <li>Setting –up custom-made trainings for your organization</li>
            <li>Requirements Engineering (RE)</li>
            <li>Individual developments and growth</li>
            <li>Scalable employee branding</li>
        </SupportEn>
        <section className="section-x-margin section-x-padding">
            <PreHeading>Overview</PreHeading>
            <h2>Features</h2>

            <IconRow className="mt-16 mobile:mt-12" fluid>
                <IconRowItem icon={<LayersIcon />}>
                    Quiz Based Trainings
                </IconRowItem>
                <IconRowItem icon={<PieIcon />}>
                    Data Analysis
                </IconRowItem>
                <IconRowItem icon={<TabletIcon />}>
                    Anywhere at Anytime
                </IconRowItem>
                <IconRowItem icon={<TrendingUpIcon />}>
                    Live Data and Assessment
                </IconRowItem>
                <IconRowItem icon={<UmbrellaIcon />}>
                    Employee Branding Experiences
                </IconRowItem>
                <IconRowItem icon={<FilterIcon />}>
                    News
                </IconRowItem>
                <IconRowItem icon={<MessageIcon />}>
                    Instant Feedback
                </IconRowItem>
                <IconRowItem icon={<SmileIcon />}>
                    Gamification and Motivation
                </IconRowItem>
                <IconRowItem icon={<UsersIcon />}>
                    User Management
                </IconRowItem>
                <IconRowItem icon={<FeatherIcon />}>
                    Content Management System
                </IconRowItem>
                <IconRowItem icon={<SettingsIcon />}>
                    Training Management
                </IconRowItem>
            </IconRow>
        </section>
        <section className="section-x-margin">
            <CustomerSlider />
        </section>
        <StartNowEn />
    </Layout>
}

export default CultureEn
